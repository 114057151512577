import React, { useState } from 'react';
import axios from 'axios';

const MessageParser = ({ children, actions, lim}) => {
  const [files, setFiles] = useState([]);

  const { checker } = children.props.state;
  const [limit,setLimit] = useState(40)
  const [file, setFile] = useState();
  function handleFile(event) {
    setFiles(event.target.files);
  }

  async function handleUpload() {
  actions.handleSubmit(files)
  }

  const parse = (message) => {

}

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}

    <div className="range">
    <div className='pdfu' id='pdfid'>
    <p className='updf'>Upload pdf</p>
  
        <input type="file" name="files" multiple onChange={handleFile} className='pdf' ></input>
        <br/>
        <button  onClick={handleUpload} className='upload'>upload</button>
    </div>
    </div>

    </div>
  );
};

export default MessageParser;