import { createChatBotMessage } from 'react-chatbot-kit';
import BotAvatar from '../BotAvatar/BotAvatar';
import UserAvatar from '../BotAvatar/UserAvatar';
import StartBtn from '../Components/StartBtn';
import Review from '../Components/Review';


const config = {
  
    initialMessages: [createChatBotMessage('Hey admin! Welcome back 👨🏻‍💻', {
      widget: 'startBtn',
    })],
    botName: "Admin's Bot",
    customComponents:{
      botAvatar: (props) => <BotAvatar/>,
      userAvatar: (props) => <UserAvatar/>
    },
    widgets:[
      {
        widgetName: 'startBtn',
        widgetFunc: (props) => <StartBtn {...props} />,
      },
      {
        widgetName: 'review',
        widgetFunc: (props) => <Review {...props} />,
      },
    ]
  
};

export default config;