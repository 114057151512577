import React, { useRef,useState, useEffect } from 'react';
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { auth, db } from '../firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";


const PreBuilt = () => {

  const [user, setUser] = useState(null);
  const [usermail, setUsermail] = useState(null);
  const [superadmin, setSuperadmin] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [selectedcompany, setSelectedcompany] = useState(null);
    const [selectedyear, setSelectedyear] = useState(null);
    const [selectedquarter, setSelectedquarter] = useState("")
    const [selectedquery, setSelectedquery] = useState("")
    const [availableYears, setAvailableYears] = useState([]);
    const [availableQuarters, setAvailableQuarters] = useState([]);
    const [botresponse, setBotresponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [accordionData, setAccordionData] = useState([]);
    const [expandedItems, setExpandedItems] = useState([]);


    const [persona, setPersona] = useState("");
    const [context, setContext] = useState("");
    const [template, setTemplate] = useState("");
    const [prompt, setPrompt] = useState(null);
    const [actualprompt, setActualprompt] = useState(null);



    const apiUrl = 'https://earning-call-index-gcp-5wz7dep6ya-uc.a.run.app/get_answer';

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((currentUser) => {
        if (currentUser) {
          setUser(currentUser);
          setUsermail(currentUser.email)
          if(currentUser.email=='vishnu.dn@dataknobs.com' || currentUser.email=='prashantk@dataknobs.com' || currentUser.email=='vinayak.g@dataknobs.com' || currentUser.email=='priyanshu.a@dataknobs.com'){
            setSuperadmin(true);
          }
        }
      });



  
      return () => unsubscribe();
    }, []);





    const toggleAccordion = (index) => {
      const currentIndex = expandedItems.indexOf(index);
      const newExpandedItems = [...expandedItems];
      if (currentIndex === -1) {
          newExpandedItems.push(index); // Expand if not already expanded
      } else {
          newExpandedItems.splice(currentIndex, 1); // Collapse if already expanded
      }
      setExpandedItems(newExpandedItems);
  };


    const successToast = async () => {
      
      const toastLiveExample = document.getElementById('liveToast1');
      const toast = new bootstrap.Toast(toastLiveExample);
      toast.show();
    }

    const failureToast = async () => {
      const toastLiveExample = document.getElementById('liveToast2');
      const toast = new bootstrap.Toast(toastLiveExample);
      toast.show();
    }

  
    // useEffect(() => {
    //     const fetchCompanies = async () => {
    //       try {
    //         const response = await axios.get('https://chat-history-api-5wz7dep6ya-uc.a.run.app/fetch_data');
    //         if (response.status === 200) {
    //           setCompanyList(response.data.companies);
    //           console.log(response.data.companies)
    //         } else {
    //           console.error('Failed to fetch companies');
    //         }
    //       } catch (error) {
    //         console.error('Error:', error);
    //       }
    //     };
      
    //     fetchCompanies();
    //   }, []);

    useEffect(() => {
      const fetchCompanies = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, 'earning-call-index'));
  
          const companies = querySnapshot.docs.map(doc => doc.data());
          // setCompanyList(companies);
          console.log("company list :");
          console.log(companies[0].company);
          setCompanyList(companies[0].company);
        } catch (error) {
          console.error('Error fetching companies:', error);
          setCompanyList([]);
        }
      };
    
      fetchCompanies();
    }, []);
  




      
      const fetchData = async () => {
        const companyDetails = selectedcompany + "_" + selectedyear + "_" + selectedquarter;
        try {
            const response = await axios.get(`https://chat-history-api-5wz7dep6ya-uc.a.run.app/fetch_faqs/data?cname=${companyDetails}`);
            if (response.status === 200) {
              if(response.data.output!="No FAQ's found for given company quarter and year."){
                setAccordionData(response.data.output);
                console.log(response.data.output)
              }

            } else {
                console.error('Failed to fetch data');
                setAccordionData([]);
            }
        } catch (error) {
            console.error('Error:', error);
            setAccordionData([]);
        }
    
        // try {
        //     // Fetch the prompt data
        //     const promptResponse = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/stocksPrompt/defaultPrompt`);
        //     if (promptResponse.status === 200) {
        //         // Update the prompt state with the fetched prompt data
        //         setPrompt(promptResponse.data.prompt.finalPrompt)
        //     } else {
        //         console.error('Failed to fetch prompt data');
        //         setPrompt(null);
        //     }
        // } catch (error) {
        //     console.error('Error fetching prompt:', error);
        //     setPrompt(null);
        // }

        
    };

    const fetchPrompt = async () => {
              try {
            // Fetch the prompt data
            const promptResponse = await axios.get(`https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/stocksPrompt/defaultPrompt`);
            if (promptResponse.status === 200) {
                // Update the prompt state with the fetched prompt data
                setPrompt(promptResponse.data.prompt.finalPrompt)
            } else {
                console.error('Failed to fetch prompt data test');
                setPrompt(null);
            }
        } catch (error) {
            console.error('Error fetching prompt test:', error);
            setPrompt(null);
        }
    }

    const generatePrompt = () => {
      
      const totalPrompt = persona + ". " + context + ". " + template;
      setPrompt(totalPrompt);

    }




    const generateActualPrompt = () => {
      // Replace placeholders in the prompt string with actual selected values
      const replacedPrompt = prompt
        .replace(/{company}/g, selectedcompany)
        .replace(/{year}/g, selectedyear)
        .replace(/{quarter}/g, selectedquarter);
    
      // Set the updated prompt to actualprompt state
      setActualprompt(replacedPrompt);
    };

    useEffect(() => {
      console.log(actualprompt);
    }, [actualprompt]);
    
    


      useEffect(() => {
        if(prompt){
           generateActualPrompt();
          }
        
    }, [prompt]);

    useEffect(()=>{
      fetchPrompt();
    }, [])

    useEffect(() => {
      setBotresponse(null)
      fetchData();
      
  }, [selectedquarter]);

      const updateselect = async (data, check) => {
        if (check === "company") {
          setSelectedcompany(data);
          // Filter the companyList to get entries for the selected company
          const selectedCompanyData = companyList.filter(company => company.name === data);
      
          if (selectedCompanyData.length > 0) {
            // Get all years associated with the selected company
            const allYears = selectedCompanyData.flatMap(company => company.year);
            const uniqueYears = Array.from(new Set(allYears));
            setAvailableYears(uniqueYears);
      
            // Clear selected year and quarter when changing the company
            setSelectedyear("");
            setSelectedquarter("");
          }
        } else if (check === "year") {
        
      
          // Filter the companyList to get entries for the selected company
          const selectedCompanyData = companyList.filter(company => company.name === selectedcompany);
      
          if (selectedCompanyData.length > 0) {
            // Get all quarters associated with the selected company and year
            const selectedYearData = selectedCompanyData.filter(company => company.year === data);
            const allQuarters = selectedYearData.flatMap(company => company.quarter);
            const uniqueQuarters = Array.from(new Set(allQuarters));
            setAvailableQuarters(uniqueQuarters);
      
            // Clear selected quarter when changing the year
            setSelectedquarter("");
            setSelectedyear(data);
          }
        } else if (check === "quarter") {
          setSelectedquarter(data);
          console.log(selectedcompany + " " + selectedyear + " " + data)
        }
      };
      
      const handleInputChange = (event) => {
        setSelectedquery(event.target.value); // Update inputValue state when input changes
    };

    const handlePersonaChange = (event) => {
      setPersona(event.target.value); // Update inputValue state when input changes
  };

  const handleContextChange = (event) => {
    setContext(event.target.value); // Update inputValue state when input changes
};

const handleTemplateChange = (event) => {
  setTemplate(event.target.value); // Update inputValue state when input changes
};




const submitPrompt = async () => {
  const editedContent = document.querySelector('.prompt').textContent;
  try {
    const response = await axios.post('https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/stocksPrompt', {
      companyDetails: "defaultPrompt",
      finalPrompt: editedContent
    });

    if (response.status === 201) {
      // Prompt saved successfully
      successToast();
      generateActualPrompt()
    }else if (response.status === 200){
      successToast();

    }
    
    else {
      // Prompt submission failed
      failureToast();
    }
  } catch (error) {
    console.error('Error submitting prompt:', error);
    // Handle error
  }
};



      const generateAnswer = async () => {

        // setBotresponse("test botresponse")

const updatedPrompt = actualprompt + selectedquery;

const data = {
  company: selectedcompany,
  year: selectedyear,
  quarter: selectedquarter,
  query: updatedPrompt
};

const apiUrl = 'https://earning-call-index-gcp-5wz7dep6ya-uc.a.run.app/get_answer';

setLoading(true);

try {
  const response = await axios.post(apiUrl, data);
  // setIsLoading(false);

  if (response.status === 200) {
    setLoading(false)
    setBotresponse(response.data.ans);


    } else {
      console.log('error');
    }
  } catch (error) {
    setLoading(false);
    setBotresponse('Error:', error);
    
  }
      }


      const submitQnA = async () => {

        const editedContent = document.querySelector('.response').textContent;
        const companyDetails = selectedcompany + "_" + selectedyear + "_" + selectedquarter;
        const response = await axios.post("https://chat-history-api-5wz7dep6ya-uc.a.run.app/create_faqs", {
          company_name : companyDetails,
          question : selectedquery,
          answer: editedContent // Answer will be generated by the server
        });
    
        if (response.status === 200) {
          successToast();
          setBotresponse(null)
          fetchData();
        } else {
          failureToast();
          console.error('Failed to generate answer');
        }
      }
      
    return (

        <div style={{paddingTop:"5%", paddingLeft:'5%'}} className='prebuilt'>

{superadmin ? (<>

<h3>Add prompt template</h3>

<div style={{display:"flex"}}>


<div class="card text mb-3 shadow-lg" style={{width: "33vw", height:"50vh", paddingLeft:'2rem',paddingRight:'2rem', paddingTop:'2rem', paddingBottom:'2rem', marginTop:"5%"}}>

<h6 style={{color:'grey'}}>System Persona</h6>
<div class="input-group mb-3" style={{width:"max-content", display:'flex', width:"90%"}}>
<div><input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder='Enter your question' style={{width:"26vw", fontSize:"0.8rem"}} value={persona} onChange={handlePersonaChange}/></div>
</div> 

<h6 style={{color:'grey'}}>System Context</h6>
<div class="input-group mb-3" style={{width:"max-content", display:'flex'}}>
<div><input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder='Enter your question' style={{width:"26vw", fontSize:"0.8rem"}} value={context} onChange={handleContextChange}/></div>
</div> 

<h6 style={{color:'grey'}}>Prompt Template</h6>
<div class="input-group mb-3" style={{width:"max-content", display:'flex'}}>
<div><input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder='Enter your question' style={{width:"26vw", fontSize:"0.8rem"}} value={template} onChange={handleTemplateChange}/></div>

</div> 

<div> <button type="button" class="btn btn-primary"  style={{width:'max-content', color:'white', alignSelf:'right', justifyContent:'right', marginLeft:'60%',marginTop:"5%"}} onClick={generatePrompt}>Generate Prompt</button></div>

</div>



{prompt && (
  <div class="card text mb-3 shadow-lg" style={{ width: "33vw", maxHeight: "50vh", overflowY: "auto", paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '2rem', paddingBottom: '2rem', marginTop: "5%", marginLeft: "5%", position: "relative" }}>
    <div className="prompt" style={{ marginBottom: "50px" }} contentEditable="true">
      {prompt}
    </div>
    <div style={{ position: "absolute", bottom: "30px", right: "30px" }}>
      <button type="button" class="btn btn-primary" style={{ width: 'max-content', color: 'white' }} onClick={submitPrompt}>Submit Prompt</button>
    </div>
  </div>
)}




</div>




<h3 style={{marginTop:"5%"}}>Add pre-built questions</h3>


<div style={{display:"flex", width:"70vw", marginTop:"5%"}}>



<div class="dropdown-center" >
<button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  {selectedcompany ? selectedcompany : "Select Company"}
</button>
<ul class="dropdown-menu" style={{ maxHeight: '20vh', overflowY: 'auto' }}>
  {[...new Set(companyList.map(company => company.name))].map((companyName) => (
    <li key={companyName}>
      <button class="dropdown-item" onClick={() => updateselect(companyName, "company")}>
        {companyName}
      </button>
    </li>
  ))}
</ul>
</div>




<div class="dropdown-center" style={{marginLeft:"5%"}}>
<button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" color='black'>
  {selectedyear ? selectedyear : "Select Year"}
</button>
<ul class="dropdown-menu" >
  {availableYears.map((year) => (
      <li key={year}>
          <button class="dropdown-item" onClick={() => updateselect(year, "year")} >
              {year}
          </button>
      </li>
  ))}
</ul>

</div>



<div class="dropdown-center" style={{marginLeft:"5%"}}>
<button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  {selectedquarter ? selectedquarter : "Select Quarter"}
</button>
<ul class="dropdown-menu">
  {availableQuarters.map((quarter) => (
    <li key={quarter}>
      <button class="dropdown-item" onClick={() => updateselect(quarter, "quarter")}>
        {quarter}
      </button>
    </li>
  ))}
</ul>
</div>


</div>



<div style={{display:"flex", marginTop:"5vh"}}>


<div class="card text mb-3 shadow-lg" style={{width: "33vw", height:"50vh", paddingLeft:'2rem',paddingRight:'2rem', paddingTop:'3rem', paddingBottom:'3rem'}}>
<h4 style={{color:'grey'}}>Generate question</h4><br/>
<div class="input-group mb-3" style={{width:"max-content", display:'flex'}}>
<div>
<input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder='Enter your question' style={{width:"20vw"}} value={selectedquery} onChange={handleInputChange}/></div>
<div> <button type="button" class="btn btn-primary"  style={{width:'max-content', color:'white', alignSelf:'right', justifyContent:'right', marginLeft:'5%'}} onClick={generateAnswer}>Generate</button></div>
</div>
</div>




<div>



{botresponse && (
  <div class="card text mb-3 shadow-lg" style={{ width: "33vw", maxHeight: "50vh", overflowY: "auto", paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '2rem', paddingBottom: '2rem', marginTop: "5%", marginLeft: "5%", position: "relative" }}>
    <div className="response" style={{ marginBottom: "50px" }} contentEditable="true">
      {botresponse}
    </div>
    <div style={{ position: "absolute", bottom: "30px", right: "30px" }}>
      <button type="button" class="btn btn-primary" style={{ width: 'max-content', color: 'white' }} onClick={submitQnA}>Submit</button>
    </div>
  </div>
)}


{/* 





{botresponse && 
<div>
<div class="response card text mb-3 shadow-lg" style={{ width: "33vw", maxHeight: "50vh", overflowY: "auto", paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '2rem', paddingBottom: '2rem', marginTop: "5%", marginLeft: "5%", position: "relative" }} contentEditable="true">

{botresponse}


</div>

<div style={{ position: "absolute", bottom: "30px", right: "30px" }}>
  <button type="button" class="btn btn-primary" style={{ width: 'max-content', color: 'white' }} onClick={submitQnA}>Submit Prompt</button>
</div>
</div>
} */}



</div>

{loading &&<div style={{marginLeft:"25%", marginTop:"10%"}}> <p className="p-3 bg-secondary bg-opacity-5 border border-info border-start-0 rounded-end" style={{ padding:'10px', color:'white', borderRadius:'10px'}} contentEditable="true" data-bs-theme="blue"><div class="dots"></div></p></div>}


</div>





<br/>
<br/>


<div className="accordion" id="accordionExample">
              {accordionData.map((item, index) => (
                  <div className="accordion-item " key={index}>
                      <h2 className="accordion-header " id={`heading${index}`}>
                          <button
                              className={`accordion-button shadow border border-success border-opacity-25 ${expandedItems.includes(index) ? '' : 'collapsed'}`}
                              type="button"
                              onClick={() => toggleAccordion(index)} // Toggle on click
                          >
                              {item.question ? item.question : 'Question'}
                          </button>
                      </h2>
                      <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${expandedItems.includes(index) ? 'show' : ''}`}
                          aria-labelledby={`heading${index}`}
                          data-bs-parent="#accordionExample"
                      >
                          <div className="accordion-body" style={{ paddingLeft: "2%" }}>
                              {item.answer ? item.answer : 'Answer'}
                          </div>
                      </div>
                  </div>
              ))}
          </div>
          <br/>
<br/>




          <div class="toast-container position-fixed bottom-0 end-0 p-3">
<div id="liveToast1" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="me-auto" style={{color:"green"}}>Success!</strong>
    <small>Just now</small>
    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
  <div class="toast-body">
    Your question and answer has been saved!
  </div>
</div>
</div>








<div class="toast-container position-fixed bottom-0 end-0 p-3">
<div id="liveToast2" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="me-auto" style={{color:"red"}}>Error!</strong>
    <small>Just now</small>
    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
  <div class="toast-body">
    There was an error saving your question
  </div>
</div>
</div>






</>) : (<h4 style={{color:'darkgrey', marginLeft:'30%', marginTop:'10%', marginRight:"40%"}}><AiOutlineCodeSandbox/>&nbsp; You don't have access :(</h4> )}














        </div>

      );
}
 
export default PreBuilt;